import request from '@/utils/request'
import { 
  ParamEstimateFreight, 
  SubmitPayment, 
  ParamsPayment, 
  PrintOrderList, 
  ContractPrintOrder,
  ExpressEditVO
} from '@/interface/print'
import { CompanyInvoiceCode } from "@/views/InvoiceExpressOrder/data"
import qs from 'qs'

enum Api {
  GET_PhoneLogin = '/news/getHomeRightNews',
  GET_queryByContractId = '/print-server/print/contractPrint/queryByContractId',
  GET_queryByContractIds = '/print-server/print/contractPrint/queryByContractIds',
  ADD_PrintItem = '/print-server/print/contractPrint/addPrintItemByIds',
  EDIT_printItem = '/print-server/print/printItem/edit',
  PAY_paymentWxPay = '/payment-server/pay/wxPay/scanCode2',
  query_printPrice = '/print-server/print/printPrice/query',
  // SB_submitPayment = '/print-server/print/printItem/submitPayment',
  SB_submitPayment = '/print-server/print/contractPrintOrder/addWithPrintItem',
  query_paymentStatus = '/payment-server/pay/wxPay/queryOrder',
  queryEstimateFreight = '/express-server/express/expressOrder/queryEstimateFreight',
  GET_contractPrintOrderList = '/print-server/print/contractPrintOrder/list',
  GET_printJob = '/print-server/print/contractPrintOrder/printJob',
  cloudPrintByIds = '/print-server/print/cloud/printByIds?ids=',
  placeOrders = '/print-server/print/contractPrintOrder/placeOrders',
  parseAddress = '/print-server/print/contractPrintOrder/parseAddress?contractId=',
  updatePagesByContractId = '/print/contractPrint/updatePagesByContractId?contractId=',
  printExpressImg = '/print-server/print/contractPrintOrder/printExpressImg',
  expressOrderGetRoute = '/express-server/express/expressOrder/getRoute',
  expressEdit = '/print-server/print/contractPrintOrder/expressEdit',
  contractPrintOrderList = '/print-server/print/contractPrintOrder/list',
  printByIdsPrintOrder = '/print-server/print/contractPrintOrder/printByIds?ids=',
  printRefund = '/print-server/print/contractPrintOrder/refund',
  eleContractPrintOrder = '/print-server/print/contractPrintOrder/eleContractPrintOrder?contractId=',
  // 发票邮寄接口
  invoiceExpressOrderList = '/express-server/invoice/invoiceExpressOrder/list',
  addAndGetPayment = '/express-server/invoice/invoiceExpressOrder/addAndGetPayment',
  invoicePlaceOrders = '/express-server/invoice/invoiceExpressOrder/placeOrders',
  invoiceParseAddress = '/express-server/invoice/invoiceExpressOrder/parseAddress',
  printExpressImgInvoice = '/express-server/invoice/invoiceExpressOrder/printExpressImg',
  reacquirePayment = '/express-server/invoice/invoiceExpressOrder/reacquirePayment',
  invoiceCancelOrder = '/express-server/invoice/invoiceExpressOrder/cancelOrder?invoiceExpressId=',
  invoiceCancelRefund = '/express-server/invoice/invoiceExpressOrder/refund',
  // 微信公众号数据接口
  jhManageContractList = '/wechat-server/wechat/jhManageContract/desc/list',
  jhManageContractProvince = '/wechat-server/wechat/jhManageContract/desc/province',
  jhManageContractSupervision = '/wechat-server/wechat/jhManageContract/desc/supervision',
  jhManageContractQuery = '/wechat-server/wechat/jhManageContract/query/',
  jhManageContractQueryProvince = '/wechat-server/wechat/jhManageContract/query/province',

  companyInvoiceList = '/print-server/companyInvoice/print/list',
  companyInvoiceBatchSave = '/print-server/companyInvoice/batchSave',
  companyInvoiceAppoint = '/print-server/companyInvoice/print/appoint',
  printException = '/print-server/companyInvoiceException/print/list/exception',
  printUpdate = '/print-server/companyInvoice/print/update',

  //获取分公司信息列表
  segmentationList='/hr-server/hr/bill/queryHrBillList',
  //上传社保公积金等文件
  uploadInformation='/hr-server/hr/bill/uploadPDF',
  //下载社保公积金等文件
  downloadInformation='/hr-server/hr/bill/downloadHrBill',



  //印章打印
  GET_sealPdfById='/operator-server/sealApply/apply/files',
  //获取所有公司信息列表
  selectAllCompanyurl='/hr-server/hr/bill/selectAllCompany',
  
  //文印中心
  GET_printCenterAds = '/express-server/express/expressSendAddress/list',
  // 距离最近的文印中心
  GET_RecentlyPrintCenter = '/express-server/express/expressSendAddress/getRecentlyPrintCenter'
}

// 更新-票
export const printUpdate = (params: CompanyInvoiceCode): any => request.post(Api.printUpdate, params)
// 查看打印任务的异常信息
export const printException = (params: PrintOrderList): any => request.get(Api.printException, params)
// 指定票号打印
export const companyInvoiceAppoint = (params: { code: string; type?: string }): any => request.get(Api.companyInvoiceAppoint, params)
// 发票列表
export const companyInvoiceList = (params: PrintOrderList): any => request.get(Api.companyInvoiceList, params)
// 批量添加开票申请
export const companyInvoiceBatchSave = (params: {
  "endInvoiceCode": string,
  "invoiceType": string,
  "startInvoiceCode": string
}): any => request.post(Api.companyInvoiceBatchSave, params)

// 分割单列表
export const segmentationList = (params: PrintOrderList): any => request.get(Api.segmentationList, params)
// 分割单所有公司列表
export const selectAllCompanyList = (params: PrintOrderList): any => request.get(Api.selectAllCompanyurl, params)
//上传社保公积金等文件
export const uploadInformation = (params: {companyId:any;category:any}): any => request.post(Api.uploadInformation, params)
  //下载社保公积金等文件
export const downloadInformation = (params: any): any => request.get(Api.downloadInformation, params)





// 微信公众号根据对应省份排名
export const jhManageContractQueryProvince = (params: { province: any }): any => request.get(Api.jhManageContractQueryProvince, params)
// 根据id查询法人详细资料
export const jhManageContractQuery = (params: { companyId: any }): any => request.get(Api.jhManageContractQuery + params.companyId)
// 获取全国公司排名信息
export const jhManageContractList = (params: { province: any }): any => request.get(Api.jhManageContractList, params)
// 获取各省公司排名信息
export const jhManageContractProvince = (params): any => request.get(Api.jhManageContractProvince, params)
// 获取分子公司公司排名信息
export const jhManageContractSupervision = (params): any => request.get(Api.jhManageContractSupervision, params)


// 发票邮寄订单-订单退款
export const invoiceCancelRefund = (params: { refundAmount: string | number; id: string; } ): any => request.post(Api.invoiceCancelRefund ,params)
// 发票邮寄订单-取消订单
export const invoiceCancelOrder = (params: { invoiceExpressId: any } ): any => request.put(Api.invoiceCancelOrder + params.invoiceExpressId)
// 发票邮寄订单-重新获取付款码
export const reacquirePayment = (params: { invoiceExpressId: any } ): any => request.get(Api.reacquirePayment, params)
// 发票发快递-重新打印面单
export const printExpressImgInvoice = (params: { id: any } ): any => request.get(Api.printExpressImgInvoice, params)
// 获取地址
export const invoiceParseAddress = (params: { ids: any } ): any => request.get(Api.invoiceParseAddress, params)
// 发票发快递
export const invoicePlaceOrders = (params: { invoiceExpressId: any } ): any => request.get(Api.invoicePlaceOrders, params)
// 发票邮寄接口
export const invoiceExpressOrderList = (params: PrintOrderList ): any => request.get(Api.invoiceExpressOrderList, params)
// 发票邮寄订单-添加并返回付款码
export const addAndGetPayment = (params: SubmitPayment ): any => request.post(Api.addAndGetPayment, params)


// 合同打印订单微信退款
// export const printRefund = (params: { id: string; refundAmount?: string } ): any => request.post(
//   Api.printRefund + 
//   '?id=' + 
//   params.id + 
//   (params.refundAmount ? ('&refundAmount=' + params.refundAmount) : '')
// )
export const printRefund = (params: { id: string; refundAmount?: string } ): any => request.post(Api.printRefund, params)
// 合同打印订单-电子合同打印订单
export const eleContractPrintOrder = (params: { contractId: string } ): any => request.post(Api.eleContractPrintOrder + params.contractId)
// 通过orderId打印
export const printByIdsPrintOrder = (params: { ids: any } ): any => request.put(Api.printByIdsPrintOrder + params.ids)
// 合同打印订单-收件地址变更
export const expressEdit = (params: ExpressEditVO ): any => request.put(Api.expressEdit, params)
// 顺丰订单-查询物流轨迹
export const expressOrderGetRoute = (params: { mailNos: any } ): any => request.get(Api.expressOrderGetRoute, params)
// 合同打印订单-重新打印面单
export const printExpressImg = (params: { id: any } ): any => request.get(Api.printExpressImg, params)
// 刷新文件
export const updatePagesByContractId = (params: { contractId: any } ): any => request.put(Api.updatePagesByContractId + params.contractId)
// 地址解析
export const parseAddress = (params: { contractId: any } ): any => request.post(Api.parseAddress + params.contractId)
// 提交顺丰
export const setPlaceOrders = (params: { printOrderIds: any } ): any => request.get(Api.placeOrders, params)
// 提交打印
export const setPrintByIds = (params: { ids: any } ): any => request.put(Api.cloudPrintByIds + params.ids)
// 获取订单打印任务
export const getPrintJob = (params: { printOrderId: any } ): any => request.get(Api.GET_printJob, params)
// 顺丰订单查询
export const contractPrintOrderList = (params: PrintOrderList & ContractPrintOrder ): any => request.get(Api.GET_contractPrintOrderList, params)
// 打印订单查询
export const queryContractPrintOrderList = (params: PrintOrderList ): any => request.get(Api.contractPrintOrderList, params)
// 顺丰订单-查询运费
export const queryEstimateFreight = (params: ParamEstimateFreight): any => request.post(Api.queryEstimateFreight, params)
// 付款信息-通过id查询
export const queryPaymentStatus = (params: { outTradeNo: string }): any => request.get(Api.query_paymentStatus, params)
// 提交订单
export const submitPayment = (params: SubmitPayment): any => request.post(Api.SB_submitPayment, params)
// 打印单价-查询单价
export const queryPrintPrice = (): any => request.get(Api.query_printPrice)
// 付款信息-付款
export const paymentWxPay = (params: ParamsPayment): any => request.get(Api.PAY_paymentWxPay, params)
export const getHomeRightNews = (params: { id: string }): any => request.get(Api.GET_PhoneLogin, params)
// 获取多个合同打印文件列表
export const getContractPrints = (params: { contractIds: string,mark: any,targetFile:any }): any => request.get(Api.GET_queryByContractIds, params)
// 获取单个合同打印文件列表
export const getContractPrint = (params: { contractId: string }): any => request.get(Api.GET_queryByContractId, params)
// 获取打印信息列表
export const addPrintItem = (params: { ids: string }): any => request.get(Api.ADD_PrintItem, params)
// 修改打印信息
export const editPrintItem = (params: any): any => request.put(Api.EDIT_printItem, params)

//印章打印
//印章附件列表
export const sealPdfList = (params: { id: string }): any => request.get(Api.GET_sealPdfById, params)


//文印中心地址
export const printCenterAdsList = (params: { pageNo: Number,pageSize: Number }): any => request.get(Api.GET_printCenterAds, params)
// 获取距离最近的文印中心
export const getNearPrintCenterLink = ():any=>request.get(Api.GET_RecentlyPrintCenter)